<template>
    <ul class="context-menu">
      <li v-for="item in menuItems" :key="item.id" @click="cntxEvent(item.handler)">{{ item.name }}</li>
    </ul>
</template>

<script>
export default {
  name: 'ContextMenu',
  props: {
    menuItems: Array
  },
  data: () => ({
    style: null,
    ctxMenuData: null,
    // the options schema
    // [
    //   {
    //     title: string,
    //     type: string, // default is undefined
    //     handler: function
    //   }
    // ]
    ctxMenuRect: null
    // {
    //   y:number,
    //   x:number
    // }
  }),
  mounted () {
    // Listen on contextmenu event through the $root instance
    this.$root.$on('contextmenu', data => {
      // if the data is null reset and handler the action
      if (data === null) this.resetCtx()
      else this.onContextMenu(data.event, data.ctxMenuData)
    })
  },
  methods: {
    resetCtx () {
      this.ctxMenuData = null
      this.ctxMenuRect = null
    },
    cntxEvent (handler) {
      handler()
    },
    onContextMenu (ev, ctxMenuData) {
      // prevent default behaviours
      ev.preventDefault()
      ev.stopPropagation()
      this.ctxMenuData = ctxMenuData
      this.ctxMenuRect = {
        x: ev.x,
        y: ev.y
      }
      // populate the option
      this.onData()
      // then reevaluate and set context-menu position
      this.reevaluatePosition()
    },
    async reevaluatePosition () {
      if (this.ctxMenuRect) {
        // using $nextTick to daley and make sure that the context-menu
        // options are fully rendered which will help us
        // to get the accurate height
        await this.$nextTick()
        await this.$nextTick()
        let { x, y } = this.ctxMenuRect
        // get the window current inner height and width
        let { innerHeight, innerWidth } = window
        // get the component height and width through element.getClientRects
        let { height, width } = this.$el.getClientRects()[0]
        // then subtract window inner height and width with
        // context-menu event source points (x, y)
        let dY = innerHeight - y
        let dX = innerWidth - x
        // check if the context-menu height is not
        // longer than the available
        if (dY < height) {
          y = y - height
        }
        if (dX < width) {
          x = x - width
        }
        // set the position
        this.style = { display: 'block', left: x + 'px', top: y + 'px' }
      }
    },
    async onData () {
      // validate if the ctxMenuData is an array and the lenght is not less then 1
      if (Array.isArray(this.ctxMenuData) && this.ctxMenuData.length) {
        // loop through the options
        this.ctxMenuData.forEach((item, index) => {
          // if this option type is equal's to divider and the handler property value is a function
          if (item.type !== 'divider' && typeof item.handler === 'function') {
            // select the option element with the help of the refs id
            let refs = this.$refs['ctx_' + index]
            // accessing $refs prooerty with object square bracket notation alwasys returns arrays of
            // HTML Elements of Vue components instance
            // so you have to validate
            if (Array.isArray(refs)) {
              let el = refs[0]
              // then attach click event and pass an arrow function as a the
              // event handler callback
              el.addEventListener(
                'click',
                () => {
                  // then on click on the option
                  // envoke the handler
                  // and reset the the ctxMenuData to hide the context-menu
                  item.handler()
                  this.resetCtx()
                },
                false
              )
            }
          }
        })
      }
    }
  },
  beforeDestroy () {
    this.$root.$off('contextmenu', () => {})
  }
}
</script>

<style>
  .context-menu {
    display: none;
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: 100;
    overflow: hidden;
    /*border-radius: 5px;*/
    background: #fff;
    font-size: 14px;
    white-space: nowrap;
    -webkit-box-shadow: 0 0 5px rgba(0,0,0,0.3);
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    padding: 0;
    max-width: 250px;
    border: 1px solid #efefef;
    width: 100%;
  }

  .context-menu li {
    cursor: pointer;
    padding: 10px 15px;
    position: relative;
    overflow: hidden;
  }

  .context-menu li:hover {
    background-color: #efefef;
    color: #0066aa;
  }
</style>
